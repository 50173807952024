import { Component, inject, input, output, SimpleChanges } from '@angular/core';
import { ModalModule } from 'src/app/shared/modal/modal.module';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { RiskGroupObjectInformationComponent } from './object-information/object-information.component';
import { CommonModule } from '@angular/common';
import { RiskEvaluationService } from 'src/app/modules/risk-assessment/services/risk-evaluation.service';;
import { findObjectInNestedObject, mapRiskEvaluationHistory, RiskEvaluationHistoryRequestDto } from 'src/app/modules/risk-assessment/models/risk-evaluation.models';
import { BaseRiskGroupObject } from '../../models/risk-group-object.model';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-risk-group-modal',
  imports: [ModalModule, RiskGroupObjectInformationComponent, CommonModule],
  standalone: true,
  template: `
    <app-modal>
      <ng-container title>Rizika</ng-container>
      <ng-container footer>
        <button class="btn btn-small" (click)="close()">Uždaryti</button>
      </ng-container>
      <div>
        <app-risk-group-object-information [object]="riskEvaluationObject$ | async"/>
      </div>
    </app-modal>
  `
})
export class RiskGroupModalComponent {
  private riskEvaluationService = inject(RiskEvaluationService);
  private modalService = inject(ModalService);

  riskEvaluationHistoryRequest = input<RiskEvaluationHistoryRequestDto>({});
  onClose = output();

  riskEvaluationObject$: Observable<BaseRiskGroupObject> = {} as Observable<BaseRiskGroupObject>;

  ngOnInit() {
    this.modalService.onOpen();
  }

  ngOnChanges(changes: SimpleChanges) {
    //TODO : change in refactor phase
    if (changes['riskEvaluationHistoryRequest']) {
      this.fetchRiskEvaluationObject();
    }
  }

  private fetchRiskEvaluationObject() {
    this.riskEvaluationObject$ = this.riskEvaluationService.getRiskEvaluationHistory(this.riskEvaluationHistoryRequest()).pipe(
      map(result => {
        const riskEvaluationHistory = mapRiskEvaluationHistory(result);
        if (this.riskEvaluationHistoryRequest().controlObjectId) {
          return findObjectInNestedObject(
            riskEvaluationHistory.objects || [],
            (obj) => obj.id === this.riskEvaluationHistoryRequest().controlObjectId
          );
        } else if (this.riskEvaluationHistoryRequest().activityId) {
          return findObjectInNestedObject(
            riskEvaluationHistory.objects?.flatMap(obj => obj.objects || []).flatMap(obj => obj.objects || []) || [],
            (obj) => obj.id === this.riskEvaluationHistoryRequest().activityId
          );
        }
        return riskEvaluationHistory;
      })
    );
  }

  close() {
    this.modalService.onClose();
    this.onClose.emit();
  }
}
