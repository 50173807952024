import { Injectable } from '@angular/core';
import { RestBaseService } from '../../../shared/services/rest-base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { RiskEvaluationHistoryRequestDto, RiskEvaluationHistoryResponseDto, RiskEvaluationResponseDto } from '../models/risk-evaluation.models';

@Injectable({
  providedIn: 'root'
})
export class RiskEvaluationService extends RestBaseService {
  constructor(http: HttpClient) { 
    super(http); 
  }

  getRiskEvaluationHistory(query: RiskEvaluationHistoryRequestDto): Observable<RiskEvaluationHistoryResponseDto> {
    return this.GetFilteredAsync(query, `${environment.api.riskEvaluation}/history`);
  }

  getRiskEvaluation(id: number): Observable<RiskEvaluationResponseDto> {
    return this.GetByIdAsync(id, `${environment.api.riskEvaluation}`);
  }
}
