import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RiskGroup } from '../enums/risk-group.enum';

@Component({
  selector: 'app-risk-group-bubble',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      class="bubble"
      [ngStyle]="{
        'background': isInitialStatus() ? getHalfFillGradient() : getBubbleColor(),
        'border': isInitialStatus() ? '2px solid ' + getBubbleColor() : 'none'
      }">
    </div>
  `,
  styles: `
    .bubble {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: auto;
      cursor: pointer;
    }
  `
})
export class RiskGroupBubbleComponent {
  status = input.required<RiskGroup>();
  isInitialStatus = input<boolean>(false);

  getBubbleColor(): string {
    switch (this.status()) {
      case RiskGroup.VerySmall:
        return '#C8E6C9';
      case RiskGroup.Small:
        return '#4CAF50';
      case RiskGroup.Medium:
        return '#FFEB3B';
      case RiskGroup.High:
        return '#FF0000';
      case RiskGroup.None:
        return '#BDBDBD';
      default:
        return '#BDBDBD';
    }
  }

  getHalfFillGradient(): string {
    const baseColor = this.getBubbleColor();
    return `linear-gradient(to left, ${baseColor} 50%, transparent 50%)`;
  }
}