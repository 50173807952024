<div *ngIf="object()">
    <p class="go-back" *ngIf="canShowBackButton" (click)="goBack()">Grįžti</p>
    <div class="block bordered object-section-container-column">
        <div class="object-section-container">
            <app-risk-group-bubble [isInitialStatus]="!viewableObject()?.assignedRisk?.inspectionId" [status]="viewableObject()?.assignedRisk?.riskGroup || 0"></app-risk-group-bubble>
            <div>
                <h1 class="object-section-container--title">
                    {{ viewableObject()?.name }}
                </h1>
                @if(viewableObject()?.registrationNumber){
                    <p>
                        {{ viewableObject()?.registrationNumber }}
                    </p>
                }
            </div>
        </div>
        <div class="object-section-container__level">
            <p>{{viewableObject()?.level}}</p>
        </div>

    </div>
    @if(viewableObject()?.objects?.length){
        <div class="block bordered object-section-container__rest">
            @for(objectChild of viewableObject()?.objects; track objectChild){
                <div 
                    class="object-section-container__rest__element" 
                    (click)="changeObjectStructureLevel(objectChild)"
                    [ngClass]="{'highlighted': objectChild?.assignedRisk?.riskGroup === viewableObject()?.assignedRisk?.riskGroup}"
                    >
                    <div class="object-section-container">
                        <app-risk-group-bubble [isInitialStatus]="!objectChild?.assignedRisk?.inspectionId" [status]="objectChild?.assignedRisk?.riskGroup || 0"></app-risk-group-bubble>
                        <div>
                            <h1 class="object-section-container__rest--title">
                                {{ objectChild.name }}
                            </h1>
                            @if(objectChild?.registrationNumber){
                                <p>
                                    {{ objectChild.registrationNumber }}
                                </p>
                            }
                        </div>
                    </div>
                    <div class="object-section-container__level">
                        <p>{{objectChild.level}}</p>
                    </div>

                </div>       
            }
        </div>
    }
    <div *ngIf="this.currentLevel() === this.riskGroupObjectLevel.Activity && this.viewableObject()?.assignedRisk?.riskEvaluationId">
        <app-risk-activity-group-object-information [riskEvaluationId]="this.viewableObject()?.assignedRisk!.riskEvaluationId"></app-risk-activity-group-object-information>
    </div>
</div>