import { Component, effect, inject, input } from '@angular/core';
import { ModalModule } from 'src/app/shared/modal/modal.module';
import { CommonModule } from '@angular/common';
import { SharedEnumsModule } from 'src/app/shared/enums/shared-enums.module';
import { RiskGroupBubbleComponent } from 'src/app/shared/risk-group-bubble/risk-group-bubble.component';
import { RiskEvaluationService } from 'src/app/modules/risk-assessment/services/risk-evaluation.service';
import { RiskEvaluationResponseDto } from 'src/app/modules/risk-assessment/models/risk-evaluation.models';
import { EvaluationRule, evaluationRuleLabel } from 'src/app/shared/enums/evalution-rule.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-risk-activity-group-object-information',
  imports: [ModalModule, CommonModule, RiskGroupBubbleComponent, SharedEnumsModule],
  standalone: true,
  templateUrl: './activity-object-information.component.html',
  styleUrls: ['./activity-object-information.component.less']
})
export class RiskGroupActivityObjectInformationComponent {
  riskEvaluationService = inject(RiskEvaluationService);

  riskEvaluationId = input.required<number>();

  riskEvaluation$: Subject<RiskEvaluationResponseDto> = new Subject<RiskEvaluationResponseDto>();

  constructor() {
    effect(() => {
      this.fetchRiskEvaluation(); 
    })
  }

  private fetchRiskEvaluation() {
    if(!this.riskEvaluationId()) return;
    this.riskEvaluationService.getRiskEvaluation(this.riskEvaluationId()!).subscribe({
      next: (riskEvaluation) => this.riskEvaluation$.next(riskEvaluation),
    });
  }

  mappedRiskEvaluation(riskEvaluation: RiskEvaluationResponseDto | null) {
    if (!riskEvaluation) return [];
    return [
      {
        title: evaluationRuleLabel(EvaluationRule.ActivityRisk),
        points: riskEvaluation.activityPoints,
        risk: riskEvaluation.activityRisk,
        percentage: this.calculatePercentage(riskEvaluation.activityPoints, riskEvaluation.totalPoints)
      },
      {
        title: evaluationRuleLabel(EvaluationRule.CapacityIndicatorRisk),
        points: riskEvaluation.capacityIndicatorPoints,
        risk: riskEvaluation.capacityIndicatorRisk,
        percentage: this.calculatePercentage(riskEvaluation.capacityIndicatorPoints, riskEvaluation.totalPoints)
      },
      {
        title: evaluationRuleLabel(EvaluationRule.MarketRisk),
        points: riskEvaluation.marketPoints,
        risk: riskEvaluation.marketRisk,
        percentage: this.calculatePercentage(riskEvaluation.marketPoints, riskEvaluation.totalPoints)
      },
      {
        title: evaluationRuleLabel(EvaluationRule.InspectionRisk),
        points: riskEvaluation.inspectionPoints,
        risk: riskEvaluation.inspectionRisk,
        percentage: this.calculatePercentage(riskEvaluation.inspectionPoints, riskEvaluation.totalPoints)
      },
      {
        title: evaluationRuleLabel(EvaluationRule.AuditRisk),
        points: riskEvaluation.auditPoints,
        risk: riskEvaluation.auditRisk,
        percentage: this.calculatePercentage(riskEvaluation.auditPoints, riskEvaluation.totalPoints)
      },
      {
        title: evaluationRuleLabel(EvaluationRule.DeficienciesRisk),
        points: riskEvaluation.deficienciesPoints,
        risk: riskEvaluation.deficienciesRisk,
        percentage: this.calculatePercentage(riskEvaluation.deficienciesPoints, riskEvaluation.totalPoints)
      },
      {
        title: evaluationRuleLabel(EvaluationRule.IncidentsRisk),
        points: riskEvaluation.incidentsPoints,
        risk: riskEvaluation.incidentsRisk,
        percentage: this.calculatePercentage(riskEvaluation.incidentsPoints, riskEvaluation.totalPoints)
      }
    ]
  }

  calculatePercentage(points: number, totalPoints: number) {
    return `${(points / totalPoints * 100).toFixed(2)}%`;
  }
}
