import { RiskGroup } from "src/app/shared/enums/risk-group.enum";
import { BaseRiskGroupObject, RiskGroupObjectLevel } from "../../subject/models/risk-group-object.model";

export interface RiskEvaluationResponseDto
{
  riskGroup: RiskGroup;
  scoredPoints: number;
  totalPoints: number;
  inspectionId: number;
  activityId: number;
  activityRisk: RiskGroup;
  activityPoints: number;
  capacityIndicatorRisk: RiskGroup;
  capacityIndicatorPoints: number;
  marketRisk: RiskGroup;
  marketPoints: number;
  inspectionRisk: RiskGroup;
  inspectionPoints: number;
  auditRisk: RiskGroup;
  auditPoints: number;
  deficienciesRisk: RiskGroup;
  deficienciesPoints: number;
  incidentsRisk: RiskGroup;
  incidentsPoints: number;
  riskIncreasingPoints: number;
  riskDecreasingPoints: number;
  pointPercentage: string;
  riskEvaluationDate: string;
}
export interface RiskEvaluationSharedListItemResponseDto {
  riskGroup: RiskGroup;
  inspectionId?: number;
}

export interface RiskEvaluationHistoryResponseDto
{
    subjectId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    assignedRisk: RiskEvaluationHistoryRiskEvaluationResponseDto;
    controlObjects: RiskEvaluationHistoryControlObjectResponseDto[];
}

export interface RiskEvaluationHistoryControlObjectResponseDto
{
    controlObjectId: number;
    controlObjectName: string;
    assignedRisk: RiskEvaluationHistoryRiskEvaluationResponseDto;
    activityTypes: RiskEvaluationHistoryActivityTypeResponseDto[];
}

export interface RiskEvaluationHistoryActivityTypeResponseDto
{
    clsfActivityTypeId: number;
    clsfActivityTypeName: string;
    assignedRisk: RiskEvaluationHistoryRiskEvaluationResponseDto;
    activities: RiskEvaluationHistoryActivityResponseDto[];
}

export interface RiskEvaluationHistoryActivityResponseDto
{
    activityId: number;
    clsfActivityName: string;
    assignedRisk: RiskEvaluationHistoryRiskEvaluationResponseDto;
    riskEvaluationHistory: RiskEvaluationHistoryRiskEvaluationResponseDto[];
    registrationNumber: string;
}

export interface RiskEvaluationHistoryRiskEvaluationResponseDto {
    riskEvaluationId: number;
    riskGroup: RiskGroup;
    inspectionId?: number;
    isLatest: boolean;
    riskPosition: number;
    points: number;
}

export interface RiskEvaluationHistoryRequestDto {
    subjectId?: number;
    controlObjectId?: number;
    activityId?: number;
    isLatest?: boolean;
}

export const mapRiskEvaluationHistory = (data: RiskEvaluationHistoryResponseDto): BaseRiskGroupObject => {
    const mapActivityType = (
      activityType: RiskEvaluationHistoryActivityTypeResponseDto,
      parentId: number
    ): BaseRiskGroupObject => {
      return {
        id: activityType.clsfActivityTypeId,
        name: activityType.clsfActivityTypeName,
        assignedRisk: activityType.assignedRisk || RiskGroup.VerySmall,
        parentId,
        objects: activityType.activities.map(activity => ({
          id: activity.activityId,
          name: activity.clsfActivityName,
          assignedRisk: activity.assignedRisk,
          parentId: activityType.clsfActivityTypeId,
          registrationNumber: activity.registrationNumber,
          level: RiskGroupObjectLevel.Activity,
        })),
        level: RiskGroupObjectLevel.ActivityType,
      };
    };
  
    const mapControlObject = (
      controlObject: RiskEvaluationHistoryControlObjectResponseDto,
      parentId: number
    ): BaseRiskGroupObject => {
      return {
        id: controlObject.controlObjectId,
        name: controlObject.controlObjectName,
        assignedRisk: controlObject.assignedRisk || RiskGroup.VerySmall,
        parentId,
        objects: controlObject.activityTypes.map(activityType =>
          mapActivityType(activityType, controlObject.controlObjectId)
        ),
        level: RiskGroupObjectLevel.ControlObject,
      };
    };
  
    const subjectName = data.companyName ? data.companyName : ((data.firstName && data.lastName) ? `${data?.firstName} ${data.lastName}` : '');
  
    return {
      id: data.subjectId,
      name: subjectName,
      assignedRisk: data.assignedRisk || RiskGroup.VerySmall,
      objects: data.controlObjects.map(controlObject =>
        mapControlObject(controlObject, data.subjectId)
      ),
      level: RiskGroupObjectLevel.Subject,
    };
  };
  

export const findObjectInNestedObject = <T extends BaseRiskGroupObject>(objects: BaseRiskGroupObject[], predicate: (obj: BaseRiskGroupObject) => boolean): BaseRiskGroupObject => {
  for (const obj of objects) {
    if (predicate(obj)) {
      return obj;
    }
  }

  for (const obj of objects) {    
    if (obj.objects && obj.objects.length) {
        const found = findObjectInNestedObject(obj.objects as T[], predicate);
        if (found) return found;
    }
  }
    
  return {} as BaseRiskGroupObject;
}

