import { CommonModule } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { RiskEvaluationSharedListItemResponseDto, RiskEvaluationHistoryRequestDto } from 'src/app/modules/risk-assessment/models/risk-evaluation.models';
import { RiskGroupModalComponent } from 'src/app/modules/subject/modals/risk-group-modal/risk-group-modal.component';
import { RiskGroupBubbleComponent } from '../risk-group-bubble/risk-group-bubble.component';

@Component({
  selector: 'app-risk-group-label',
  standalone: true,
  imports: [CommonModule, RiskGroupModalComponent, RiskGroupBubbleComponent],
  template: `
    <app-risk-group-bubble [isInitialStatus]="!status()?.inspectionId" [status]="status()?.riskGroup || 0" (click)="this.isModalVisible.set(true)"/>
    @if (isModalVisible() && useModal()) {
        <app-risk-group-modal [riskEvaluationHistoryRequest]="riskEvaluationHistoryRequest()" (onClose)="this.isModalVisible.set(false)"/>
    }
  `
})
export class RiskGroupLabelComponent {
  status = input.required<RiskEvaluationSharedListItemResponseDto | undefined>();
  useModal = input<boolean>(false);

  subjectId = input<number>();
  controlObjectId = input<number>();
  activityId = input<number>();

  riskEvaluationHistoryRequest = computed(() => {
    return {
      subjectId: this.subjectId(),
      controlObjectId: this.controlObjectId(),
      activityId: this.activityId(),
    } as RiskEvaluationHistoryRequestDto
  });

  isModalVisible = signal(false);
}