import { Component, inject, input, signal } from '@angular/core';
import { ModalModule } from 'src/app/shared/modal/modal.module';
import { BaseRiskGroupObject, RiskGroupObjectLevel } from '../../../models/risk-group-object.model';
import { CommonModule } from '@angular/common';
import { SharedEnumsModule } from 'src/app/shared/enums/shared-enums.module';
import { RiskGroupBubbleComponent } from 'src/app/shared/risk-group-bubble/risk-group-bubble.component';
import { RiskEvaluationService } from 'src/app/modules/risk-assessment/services/risk-evaluation.service';
import { RiskGroupActivityObjectInformationComponent } from "../activity-object-information/activity-object-information.component";

@Component({
  selector: 'app-risk-group-object-information',
  imports: [ModalModule, CommonModule, RiskGroupBubbleComponent, SharedEnumsModule, RiskGroupActivityObjectInformationComponent],
  standalone: true,
  templateUrl: './object-information.component.html',
  styleUrls: ['./object-information.component.less']
})
export class RiskGroupObjectInformationComponent {
  riskEvaluationService = inject(RiskEvaluationService);
  object = input<BaseRiskGroupObject | null>({} as BaseRiskGroupObject);

  riskGroupObjectLevel = RiskGroupObjectLevel;
  currentLevel = signal<RiskGroupObjectLevel>(this.riskGroupObjectLevel.Subject);

  levelOrder: RiskGroupObjectLevel[] = [this.riskGroupObjectLevel.Subject, this.riskGroupObjectLevel.ControlObject, this.riskGroupObjectLevel.ActivityType, this.riskGroupObjectLevel.Activity];

  viewableObject = signal<BaseRiskGroupObject | null>(null);

  get canShowBackButton() {
    return this.currentLevel() !== this.object()?.level;
  }

  ngOnChanges() {
    if (!this.object()) return;

    this.currentLevel.set(this.object()!.level);
    this.viewableObject.set(this.object());
  }

  changeObjectStructureLevel(object: BaseRiskGroupObject) {
    if (!this.object()) return;

    const targetObject = this.findObjectAtLevel(object.level, object.id);
    if (targetObject) {
      this.currentLevel.set(object.level);
      this.viewableObject.set(targetObject);
    }
  }

  goBack() {
    if (this.currentLevel() === this.object()?.level) return;

    const currentIndex = this.levelOrder.indexOf(this.currentLevel());
    if (currentIndex > 0) {
      this.currentLevel.set(this.levelOrder[currentIndex - 1]);
      this.viewableObject.set(this.findObjectAtLevel(this.levelOrder[currentIndex - 1], this.viewableObject()?.parentId || 0));
    }
  }

  findObjectAtLevel(level: RiskGroupObjectLevel, id: number, current: BaseRiskGroupObject | null = this.object()): BaseRiskGroupObject | null {
    if (!current) return null;
  
    if (current.level === level && current.id === id) {
      return current;
    }
  
    if (current.objects) {
      for (const obj of current.objects) {
        const found = this.findObjectAtLevel(level, id, obj);
        if (found) {
          return found;
        }
      }
    }
  
    return null;
  }

}
