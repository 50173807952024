

<div *ngIf="(riskEvaluation$ | async) as riskEvaluation">
    <div class="block">
        <div class="table-responsive">
            <table class="table-stripes">
                <thead>
                <tr>
                    <th>Kriterijus</th>
                    <th>Rizika</th>
                    <th>Taškai</th>
                    <th>Procentinė dalis</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let criteria of this.mappedRiskEvaluation(riskEvaluation)">
                        <td>{{ criteria.title }}</td>
                        <td><app-risk-group-bubble [status]="criteria.risk" /></td>
                        <td>{{ criteria.points }}</td>
                        <td>{{ criteria.percentage}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="block">
        <div class="table-responsive">
            <table class="table-stripes">
                <thead>
                <tr>
                    <th>Papildomas veiksnys</th>
                    <th>Taškai</th>
                    <th>Procentinė dalis</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Riziką didinantis</td>
                        <td>{{riskEvaluation!.riskIncreasingPoints}}</td>
                        <td>{{calculatePercentage(riskEvaluation!.riskIncreasingPoints, riskEvaluation!.totalPoints)}}</td>
                    </tr>
                    <tr>
                        <td>Riziką mažinantis</td>
                        <td>{{riskEvaluation!.riskDecreasingPoints}}</td>
                        <td>-{{calculatePercentage(riskEvaluation!.riskDecreasingPoints, riskEvaluation!.totalPoints)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="block">
        <div class="table-responsive">
            <table class="table-stripes">
                <thead>
                <tr>
                    <th>Surinkti taškai</th>
                    <th>Iš</th>
                    <th>Procentinė dalis</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{riskEvaluation!.scoredPoints}}</td>
                        <td>{{riskEvaluation!.totalPoints}}</td>
                        <td>{{calculatePercentage(riskEvaluation!.scoredPoints, riskEvaluation!.totalPoints)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>