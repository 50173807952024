import { RiskEvaluationHistoryRiskEvaluationResponseDto } from "../../risk-assessment/models/risk-evaluation.models";

export enum RiskGroupObjectLevel {
    Subject = 'Subjekto rizika',
    ControlObject = 'Kontrolės objekto rizika',
    ActivityType = 'Veiklos grupės rizika',
    Activity = 'Veiklos rizika',
}

export interface BaseRiskGroupObject {
    id: number;
    name: string;
    assignedRisk: RiskEvaluationHistoryRiskEvaluationResponseDto;
    objects?: BaseRiskGroupObject[];
    parentId?: number;
    level: RiskGroupObjectLevel;
    registrationNumber?: string;
}